import React from 'react'
import './Paymentcard.css'
const PaymentCard = () => {
  return (
    <div className='fontss'>
        
    <div class="mainscreen">
    {/* <!-- <img src="https://image.freepik.com/free-vector/purple-background-with-neon-frame_52683-34124.jpg"  class="bgimg " alt="">-->  */}
      <div class="card223">
        <div class="leftside">
          <img
            src={require('../coffeeflavour/raspberry.png')}
            class="product"
            alt="Shoes"
          />
        </div>
        <div class="rightside">
          <form action="">
            <h1>CheckOut</h1>
            <h2>Payment Information</h2>
            <p>Cardholder Name</p>
            <input type="text" class="inputbox" name="name" required />
            <p>card223 Number</p>
            <input type="number" class="inputbox" name="card_number" id="card_number" required />

            <p>card223 Type</p>
            <select class="inputbox" name="card_type" id="card_type" required>
              <option value="">--Select a card223 Type--</option>
              <option value="Visa">Visa</option>
              <option value="RuPay">RuPay</option>
              <option value="MasterCard">MasterCard</option>
            </select>
<div class="expcvv">

            <p class="expcvv_text">Expiry</p>
            <input type="date" class="inputbox" name="exp_date" id="exp_date" required />

            <p class="expcvv_text2">CVV</p>
            <input type="password" class="inputbox" name="cvv" id="cvv" required />
        </div>
            <p></p>
            <button type="submit" class="button">CheckOut</button>
          </form>
        </div>
      </div>
    </div>
  

    </div>
  )
}

export default PaymentCard